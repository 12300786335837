<script setup lang="ts">
import { gsap } from "gsap";

import type { HomeBanners, Badge } from "@/types";

const { banner } = defineProps<{ banner: HomeBanners[number] }>();

const { t } = useT();
const prepareImgUrl = useImage();
const { isMobile } = useDevice();
const { registrationRewards, registrationRewardWithoutCode, isPersonalOffer } = useCheckRewardsData();

const coins = computed(() => registrationRewards.value?.coins || registrationRewardWithoutCode.value?.coins);
const entries = computed(() => registrationRewards.value?.entries || registrationRewardWithoutCode.value?.entries);
const paymentsImg = ["visa", "mc", "apple", "deal"] as const;
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const badgeLabel = parseJSON(banner.badgeLabel || "{}") as Badge;

const bgDesktop = `url(${prepareImgUrl(`${baseImageUrl}${banner?.backgroundImage2x}`, {
	format: "avif",
	loading: "lazy"
})})`;

const bgMobile = computed(() => `url(${baseImageUrl}${banner?.imageMobile})`);
const badgeBg = computed(() => `url(${baseImageUrl}${banner?.imageBadge})`);
const animal = computed(() => `url(${baseImageUrl}${banner?.imageMobile2x})`);

const animateLine = () => {
	const tl = gsap.timeline({ defaults: { duration: 1.7, ease: "none", repeat: -1, yoyo: true } });
	tl.to("#gradientLine", {
		backgroundImage: "linear-gradient(90deg, #e14609 0%, #fea904 50%, #029259 100%)"
	})
		.to("#gradientLine", {
			backgroundImage: "linear-gradient(90deg, #029259 0%, #e14609 50%,  #fea904 100%)"
		})
		.to("#gradientLine", {
			backgroundImage: "linear-gradient(90deg, #fea904 0%, #029259  50%, #e14609 100%)"
		})
		.to("#gradientLine", {
			backgroundImage: "linear-gradient(90deg, #e14609 0%, #fea904 50%, #029259 100%)"
		});
};
onMounted(() => {
	animateLine();
});
</script>

<template>
	<div class="guest-main-banner">
		<section class="refistration-top">
			<div class="animal mobile" />
			<div class="content">
				<AText class="title desktop" :size="{ full: 38, md: 32 }" :modifiers="['uppercase']" as="h1">
					{{ banner?.title }}
				</AText>
				<div class="subtitle">
					<AText
						v-if="isPersonalOffer && entries"
						class="text-primary-400 by-code"
						:size="{ full: 24, md: 34 }"
						:modifiers="['uppercase', 'bold', 'center']"
					>
						<i18n-t keypath="signup.modal.prize.byCode">
							<template #coins>
								<MPrizeFund
									v-if="coins"
									variant="coins"
									icon="coins"
									is-svg
									:iconSize="isMobile ? 24 : 16"
									:offset="0.4"
								>
									{{ numberFormatWithPrefix(coins) }}
								</MPrizeFund>
							</template>
							<template #entries>
								<MPrizeFund
									v-if="entries"
									variant="entries"
									icon="entries"
									is-svg
									:iconSize="isMobile ? 24 : 16"
									:offset="0.4"
								>
									{{ numberFormatWithPrefix(entries) }}
								</MPrizeFund>
							</template>
						</i18n-t>
					</AText>
					<AText
						v-else-if="coins"
						class="text-primary-400"
						:size="{ full: 24, md: 34 }"
						:modifiers="['uppercase', 'bold']"
					>
						<i18n-t keypath="signup.modal.prize.default">
							<template #coins>
								<MPrizeFund variant="coins" icon="coins" is-svg :iconSize="isMobile ? 24 : 16" :offset="0.4">
									{{ numberFormatWithPrefix(coins) }}
								</MPrizeFund>
							</template>
						</i18n-t>
					</AText>
					<AText v-else class="text-primary-400" :size="{ full: 24, md: 34 }" :modifiers="['uppercase', 'bold']">
						{{ banner?.smallTitle }}
					</AText>
					<AText
						v-if="banner?.subTitle"
						class="text-primary-400"
						:size="{ full: 24, md: 26 }"
						:modifiers="['uppercase', 'bold']"
					>
						&nbsp;{{ banner?.subTitle }}
					</AText>
				</div>
				<OHomeForm location-tid="home" />
			</div>
			<div class="wheel-holder desktop">
				<LazyMWheelOfFortuneAnimated size="390px" />
			</div>
			<div v-if="badgeLabel" class="badge mobile">
				<div class="text">
					<AText :size="24" :modifiers="['bold', 'center']" as="div"> {{ badgeLabel.percent }}</AText>
					<AText :size="22" :modifiers="['uppercase', 'center']" as="div">{{ badgeLabel.text }}</AText>
				</div>
			</div>
			<NuxtImg
				v-for="(item, index) in [2, 1, 3, 4]"
				:id="`leave-${index + 1}`"
				:key="index"
				class="leaves desktop"
				:src="`/nuxt-img/banners/main-leave-${item}.png`"
				alt="leaves"
				densities="x1"
				format="avif"
				loading="lazy"
			/>
			<AAnimationCoins />
		</section>
		<AText
			id="gradientLine"
			class="gradient-line"
			:size="{ full: 24, md: 16 }"
			:modifiers="['uppercase', 'bold', 'center', 'condensed']"
			as="div"
		>
			{{ t("Unlimited wheel of fortune every 24H!") }}
		</AText>
		<div class="banner-bottom-payments mobile">
			<NuxtImg
				v-for="payment in paymentsImg"
				:key="`${payment}-key-payments`"
				:src="`/nuxt-img/payment-methods/${payment}.png`"
				:alt="payment"
				format="avif"
				loading="lazy"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.guest-main-banner {
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-bottom: 32px;
	@include media-breakpoint-down(md) {
		margin-top: -60px;
		margin-bottom: 0;
	}
	@include media-breakpoint-up(md) {
		border-radius: 16px;
		margin-bottom: 20px;
	}
}
.refistration-top {
	@include media-breakpoint-down(md) {
		background: v-bind(bgMobile) no-repeat top center;
		background-size: auto 100%;
	}
	@include media-breakpoint-up(md) {
		background: v-bind(bgDesktop) no-repeat center center / cover;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.animal {
		position: relative;
		z-index: 1;
		height: 48vh;
		background: v-bind(animal) no-repeat center center / contain;
		background-size: 370px auto;
		margin: -3vh 0 -15vh;
	}
	.content {
		position: relative;
		z-index: 2;
		.subtitle {
			display: flex;
			align-items: center;
			gap: 6px;
		}
		@include media-breakpoint-down(md) {
			background: linear-gradient(180deg, transparent 0, #044b3a 12%, #0e201d 100%);
			padding: 16px;
			.title {
				max-width: 280px;
				text-align: center;
				margin: 0 auto 12px;
			}
			.subtitle {
				flex-direction: column;

				.by-code {
					width: 310px;
				}
			}
			.auth {
				margin-top: 6px;
				:deep(.primary) {
					width: 100%;
					max-width: 100%;
				}
			}
		}
		@include media-breakpoint-up(md) {
			.title,
			.subtitle {
				white-space: nowrap;
			}
			z-index: 3;
			padding: 58px 0 69px 189px;
			@media (max-width: 1255px) {
				padding-left: 32px;
			}
			.subtitle {
				margin: 7px 0 19px;
			}
		}
	}
}
.wheel-holder {
	position: relative;
	max-width: 400px;
	transform: translate(18%, 33%) rotate(-12deg);
	z-index: 2;
	@media (max-width: 1120px) {
		right: 90px;
	}
}
@include media-breakpoint-down(mg) {
	.badge {
		position: absolute;
		z-index: 1;
		width: 126px;
		height: 126px;
		right: 11px;
		top: 16vh;
		background: v-bind(badgeBg) no-repeat center center / contain;
		text-shadow: 2px 2px 4px rgba(var(--neutral-950-rgb), 0.25);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.text {
			rotate: 21.5deg;
		}
	}
}
.leaves {
	pointer-events: none;
	position: absolute;
	display: block;
	height: auto;
	animation: leaves 4s linear infinite;
	@include media-breakpoint-up(md) {
		z-index: 0;
		&#leave-1 {
			width: 197px;
			left: -2px;
			top: 15px;
		}
		&#leave-2 {
			animation-delay: 2s;
			animation-duration: 5s;
			width: 184px;
			left: 0px;
			bottom: 56px;
		}
		&#leave-3 {
			animation-delay: 1s;
			animation-duration: 5s;
			width: 318px;
			right: 42px;
			top: -1px;
		}
		&#leave-4 {
			width: 200px;
			top: -2px;
			right: -2px;
		}
	}
}
@keyframes leaves {
	50% {
		transform: translateY(7px) rotate(2deg);
	}
}
.gradient-line {
	position: relative;
	z-index: 3;
	background: var(--gradient-10);
	padding: 16px;
	@include media-breakpoint-down(lg) {
		padding: 13px;
		font-size: 4vw;
	}
}
.banner-bottom-payments {
	position: relative;
	z-index: 3;
	padding: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	background: var(--neutral-50);
	img {
		max-height: 21px;
		&:last-child {
			transform: scale(1.3);
		}
	}
}
</style>
